import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { SnackBarService } from './snack-bar.service';
import * as moment from 'moment';
import { api_endpoints, base_url } from '../api/api';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  tokenName='storeToken'; 
  loginUser!:any;
  randomNumber=0;
  // baseurl = base_url;
  constructor(private _snackBarService:SnackBarService,private router:Router,private _http:HttpClient,@Inject(DOCUMENT) private _document:any ) { 
    // const domain = this._document.location.hostname||`localhost`;
    // this.baseurl=`http://${domain}:3000/`;
   }
   getPath():string{
    return this.router.url;
   }
  getToken():string|null{    
    return localStorage.getItem(`${this.tokenName}_id_token`)||null;
  }
  isSuperAdmin(){   
    return (this.getLoginUser()?.role?.name=="super admin")?true:false;
  }
  
  role(){   
    return (this.getLoginUser()?.role||'user');
  }
  getLoginUser(){
    const loginUser=localStorage.getItem(`${this.tokenName}_user`)||null;
    if(loginUser&&!this.loginUser){
      this.loginUser=JSON.parse(loginUser);
    }
    return this.loginUser;
  }
  updateLoginUser(user:any):void{    
    this.loginUser=user;    
    localStorage.setItem(`${this.tokenName}_user`,JSON.stringify(user)); 
  }
  logout(message=""):void{    
    localStorage.removeItem(`${this.tokenName}_expires_at`);
    localStorage.removeItem(`${this.tokenName}_id_token`);
    localStorage.removeItem(`${this.tokenName}_user`);
    this.loginUser=null;    
    this.router.navigate(['login']).then((navigated: boolean) => {
      if(navigated&&message) {
        this._snackBarService.openSnackBar(message, '');
      }
    });
  }
  login({email,password}:any):Observable<any>{    
    if (email&&password) {
      return this._http.post(api_endpoints.user.authenticate,{email,password});
    }else if (!email&&!password) {
      return throwError(()=>{return {error:new Error('Login credentials are required.')}});
    }else if(!email){
      return throwError(()=>{return {error:new Error('Email is required.')}});
    }else{
      return throwError(()=>{return {error:new Error('Password is required.')}});
    }    
  }
  setSession(authResult:any) {    
    const expiresAt = moment().add(authResult.expiresIn);
    this.updateLoginUser(authResult.userInfo);
    localStorage.setItem(`${this.tokenName}_id_token`, authResult.token);
    localStorage.setItem(`${this.tokenName}_expires_at`, JSON.stringify(expiresAt.valueOf()) );
  }   
  getExpiration(expiration:any) {      
    const expiresAt = JSON.parse(expiration!)||"";
    return moment(expiresAt);
  } 
  isLoggedIn() {    
    const expiration = localStorage.getItem(`${this.tokenName}_expires_at`);    
    return expiration?!moment().isBefore(this.getExpiration(expiration)):false;
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  
  
  updateUserProfile(data:any){
    if (data) {
      return this._http.post(api_endpoints.user.update_profile,data);
    }
    return throwError(()=>{return {error:new Error('Data is required')}});    
  }
  updateUserPassword(currentpassword:any,password:any){
    if (currentpassword&&password) {
      return this._http.post(api_endpoints.user.change_password,{currentpassword:currentpassword,newPassword:password});
    }
    return throwError(()=>{return {error:new Error('Password are required.')}});
  }
  resetPassword(password:any,token:any):Observable<any>{
    if (password&&token) {
      return this._http.post(api_endpoints.user.reset_password,{newPassword:password,resettoken:token});
    }
    return throwError(()=>{return {error:new Error('Password are required.')}});      
  }
  validPasswordToken(token:any):Observable<any>{    
    if (token) {
      return this._http.post(api_endpoints.user.valid_password_token,{resettoken:token});
    }
    return throwError(()=>{return {error:new Error('Token is required.')}});
  }

  forgotPassword({email}:any):Observable<any>{
    if (email) {
      return this._http.post(api_endpoints.user.password_reset_request,{email});
    }
    return throwError(()=>{return {error:new Error('Email is required.')}});
  }
  checkAuthorization(module:string,permissions:string='read'):boolean{      
      const allPermissions=this.loginUser?.role?.permissions||[];
      if(this.loginUser&&allPermissions.length>0){
        const index=allPermissions.length > 0 ? allPermissions.findIndex((item:any) => (item.module === module)) : -1;
        if(index>-1){
          return allPermissions[index][permissions]||false;
        }else{
          return false;
        }
      }else{
        return false;
      }    
  }
}
