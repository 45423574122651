import { Component } from '@angular/core';
import { ProgressBarService } from 'src/app/services/progress-bar.service';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent {
  open!:boolean;
  constructor(public _progressBarService:ProgressBarService){
    _progressBarService.progressBar$.subscribe(result => {
      this.open= result;
      // console.log(result)
    })
    _progressBarService.progressBarPercentDone$.subscribe(result => {
      
      // console.log(`progress bar percent done ${result} %`)
    })
  }
}
