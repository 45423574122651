import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  
  loginForm: FormGroup;  
  error:string|null=null;
  showPassword:boolean=false;
  
  loginWithEmail:boolean=true;

  constructor(
    private _fb: FormBuilder,
    private _authService:AuthService,
    private router:Router,private _snackBarService:SnackBarService){
      this.loginForm = this._fb.group({        
        email: ['',[Validators.email]],
        password:''
      });
    }
    
  ngOnInit() {
  }
  get f(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }
  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }
  
  login() {
    this._authService.login(this.loginForm.value).subscribe({next:(result:any)=>{
      // console.log('result-login',{result});
      
      this._authService.setSession(result.data);       
      if(this._authService.isLoggedIn()){
        
        this.router.navigate(['.']).then((navigated: boolean) => {
          if(navigated) {
            this._snackBarService.openSnackBar(result.message, '');
          }
      });
      }
    },
    error:(err:any)=>{        
      console.log(err);
      
      // return throwError(()=>new Error('Login credentials are invalid.'));
      this.error=err.error.message||'Invalid Login Credentials.';
    }});   
    
  }
}
