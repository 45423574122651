
<div [ngSwitch]="_authService.role()">
    <div class="mb-4">
      <h1 class="text-3xl text-[#2D3393]">Welcome <span class="font-semibold">{{user.userName |titlecase}}</span>!</h1>
      <!-- <h1 class="text-3xl text-[#2D3393] font-semibold">{{user.userName |titlecase}}</h1> -->
      <p class="text-[#878787]">{{_authService.role()=='Super Admin'?'Super Admin':_authService.role() |titlecase}}</p>
    </div>
    <app-super-admin-home *ngSwitchCase="'Super Admin'"></app-super-admin-home>
    <app-admin-home *ngSwitchCase="'Business Admin'"></app-admin-home>
    <app-user-home *ngSwitchDefault></app-user-home>


  </div>