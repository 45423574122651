<aside [ngSwitch]="_authService.role()" class="bg-white h-screen w-1/6 md:pt-[5%] 2xl:pt-[4%] px-3 py-4">
      <!-- Sidebar content goes here -->
      <!-- Business Admin -->
      <!-- Super Admin -->
      
      <div class="text-black"  >
         <div id="home"   class="p-3 mt-6 font-semibold cursor-pointer hover:bg-msil-blue hover:text-white rounded-lg" routerLink="" routerLinkActive="bg-msil-blue text-white" [routerLinkActiveOptions]="{exact: true}" (click)="toggleDropdown('home')" >
           <i class="fas fa-regular fa-house"></i>
           <a > Home </a>
         </div>
         <ng-container *ngSwitchCase="'Super Admin'"  [ngSwitch]="activeMenu">
          <span>
            <div  (click)="toggleDropdown('template')" 
              class="p-3 mt-1 font-semibold cursor-pointer  z-10 hover:bg-msil-blue hover:text-white rounded-lg"
              >
              <img src="assets/icons/template-menu-icon.svg" alt="" class="fas fa-regular "/>
              <a > Templates </a>
              <i class="fas fa-regular "  [ngClass]="{'fa-angle-down' : activeMenu=='template','fa-angle-right' : activeMenu!='template'}"></i>
            </div>
            <div *ngSwitchCase="'template'" class="mt-2 lg:w-[93%] 3xl:w-[92%] ml-auto  border-l-2 border-msil-blue pl-3">
              <!-- Dropdown menu items -->
              <div id="viewTemplates"  routerLink="template"  routerLinkActive="bg-msil-blue text-white"  [routerLinkActiveOptions]="{exact: true}"
                class="bg-[#F1F2FF] p-3 text-msibg-msil-blue hover:bg-msil-blue hover:text-white cursor-pointer rounded-lg">
                <a class="w-full gap-3 flex items-center whitespace-nowrap bg-transparent text-sm font-semibold active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                  >
                  
                  <i class="fas fa-regular fa-eye"></i>
                  View Templates
                </a>
              </div>
              <div id="templateLogs"  routerLink="template/logs"  routerLinkActive="bg-msil-blue text-white" 
                class="bg-[#F1F2FF] p-3 mt-2 text-msibg-msil-blue hover:bg-msil-blue hover:text-white cursor-pointer rounded-lg">
                <a class="w-full gap-3 flex items-center whitespace-nowrap bg-transparent text-sm font-semibold active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                  >
                  <i class="fas fa-regular fa-file-invoice"></i>
                  Template Logs
                </a>
              </div>
              
              <!-- Add more submenu items as needed -->
            </div>
          </span>
  
          <span>
            <div  (click)="toggleDropdown('business-admin')" 
              class="p-1 py-3 mt-1 font-semibold cursor-pointer  z-10 hover:bg-msil-blue hover:text-white rounded-lg flex items-center gap-2"
              >
              <!-- <i class="fa-regular fa-objects-column"></i> -->
              <!-- <img src="/public/assets/icons/Group.svg" width="18px" class="hover:fill-red-600 icon" alt=""> -->
              <i class="fas fa-sharp fa-light fa-people-roof"></i>
              <a > Business Admin </a>
              <i class="fas fa-regular" [ngClass]="{'fa-angle-down' : activeMenu=='business-admin','fa-angle-right' : activeMenu!='business-admin'}"></i>
            </div>
            <div *ngSwitchCase="'business-admin'" class="mt-2 lg:w-[96%] 2xl:w-[92%] ml-auto text-[10px]  border-l-2 border-msil-blue pl-3">
              <!-- Dropdown menu items -->
              <div  routerLink="business-admin/add"  routerLinkActive="bg-msil-blue text-white"
                class="bg-[#F1F2FF] px-2 py-3 mt-2 text-msibg-msil-blue hover:bg-msil-blue hover:text-white cursor-pointer rounded-lg border">
                <a class="w-full gap-1 flex items-center whitespace-nowrap bg-transparent text-sm font-semibold active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                  >
                  <i class="fas fa-solid fa-user-plus"></i>
                  Add Business Admin
                </a>
              </div>
              <div routerLink="business-admin"  routerLinkActive="bg-msil-blue text-white" [routerLinkActiveOptions]="{exact: true}"
                class="bg-[#F1F2FF] lg:px-2 lg:py-3 2xl:p-3 mt-2 text-msibg-msil-blue hover:bg-msil-blue hover:text-white cursor-pointer rounded-lg">
                <a class="w-full gap-1 flex items-center whitespace-nowrap bg-transparent text-sm font-semibold active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                  >
                  <i class="fas fa-regular fa-eye"></i>
                  View Business Admin
                </a>
              </div>
              <!-- Add more submenu items as needed -->
            </div>
          </span>
         </ng-container>
         <ng-container *ngSwitchCase="'Business Admin'" [ngSwitch]="activeMenu" >

          <span>
            <div  (click)="toggleDropdown('template')" 
              class="p-3 mt-1 font-semibold cursor-pointer  z-10 hover:bg-msil-blue hover:text-white rounded-lg"
              >
              <img src="assets/icons/template-menu-icon.svg" alt="" class="fas fa-regular "/>
              <a > Templates </a>
              <i class="fas fa-regular "  [ngClass]="{'fa-angle-down' : activeMenu=='template','fa-angle-right' : activeMenu!='template'}"></i>
            </div>
            <div *ngSwitchCase="'template'" class="mt-2 lg:w-[93%] 3xl:w-[92%] ml-auto  border-l-2 border-msil-blue pl-3">
              <!-- Dropdown menu items -->
              <div id="viewTemplates"  routerLink="template"  routerLinkActive="bg-msil-blue text-white"  [routerLinkActiveOptions]="{exact: true}"
                class="bg-[#F1F2FF] p-3 text-msibg-msil-blue hover:bg-msil-blue hover:text-white cursor-pointer rounded-lg">
                <a class="w-full gap-3 flex items-center whitespace-nowrap bg-transparent text-sm font-semibold active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                  >
                  
                  <i class="fas fa-regular fa-eye"></i>
                  View Templates
                </a>
              </div>
              <div id="templateLogs"  routerLink="template/logs"  routerLinkActive="bg-msil-blue text-white" 
                class="bg-[#F1F2FF] p-3 mt-2 text-msibg-msil-blue hover:bg-msil-blue hover:text-white cursor-pointer rounded-lg">
                <a class="w-full gap-3 flex items-center whitespace-nowrap bg-transparent text-sm font-semibold active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                  >
                  <i class="fas fa-regular fa-file-invoice"></i>
                  Template Logs
                </a>
              </div>
              <div  routerLink="template/add"  routerLinkActive="bg-msil-blue text-white"  
                class="bg-[#F1F2FF] px-2 py-3 mt-2 text-msibg-msil-blue hover:bg-msil-blue hover:text-white cursor-pointer rounded-lg">
                <a class="w-full gap-1 flex items-center whitespace-nowrap bg-transparent text-sm font-semibold active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                  >
                  <i class="fas fa-sharp fa-light fa-file-circle-plus"></i>
                  Add New Template
                </a>
              </div>
              <!-- Add more submenu items as needed -->
            </div>
          </span>
  
          <span>
            <div  (click)="toggleDropdown('user-management')" 
              class="p-1 py-3 mt-1 font-semibold cursor-pointer  z-10 hover:bg-msil-blue hover:text-white rounded-lg flex items-center gap-2"
              >
              <!-- <i class="fa-regular fa-objects-column"></i> -->
              <!-- <img src="/public/assets/icons/Group.svg" width="18px" class="hover:fill-red-600 icon" alt=""> -->
              <i class="fas fa-sharp fa-light fa-people-roof"></i>
              <a > User Management </a>
              <i class="fas fa-regular" [ngClass]="{'fa-angle-down' : activeMenu=='user-management','fa-angle-right' : activeMenu!='user-management'}"></i>
            </div>
            <div *ngSwitchCase="'user-management'" class="mt-2 lg:w-[96%] 2xl:w-[92%] ml-auto text-[10px]  border-l-2 border-msil-blue pl-3">
              <!-- Dropdown menu items -->
              <div id="addNewUsers"  routerLink="user/add"  routerLinkActive="bg-msil-blue text-white"
                class="bg-[#F1F2FF] px-2 py-3 mt-2 text-msibg-msil-blue hover:bg-msil-blue hover:text-white cursor-pointer rounded-lg border">
                <a class="w-full gap-1 flex items-center whitespace-nowrap bg-transparent text-sm font-semibold active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                  >
                  <i class="fas fa-solid fa-user-plus"></i>
                  Add New Users
                </a>
              </div>
              <div id="viewUsers" routerLink="user"  routerLinkActive="bg-msil-blue text-white" [routerLinkActiveOptions]="{exact: true}"
                class="bg-[#F1F2FF] lg:px-2 lg:py-3 2xl:p-3 mt-2 text-msibg-msil-blue hover:bg-msil-blue hover:text-white cursor-pointer rounded-lg">
                <a class="w-full gap-1 flex items-center whitespace-nowrap bg-transparent text-sm font-semibold active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                  >
                  <i class="fas fa-regular fa-eye"></i>
                  View Users
                </a>
              </div>
              <!-- Add more submenu items as needed -->
            </div>
          </span>
         </ng-container>
         <ng-container  *ngSwitchDefault [ngSwitch]="activeMenu">
          <span>
            <div  (click)="toggleDropdown('template')" 
              class="p-3 mt-1 font-semibold cursor-pointer  z-10 hover:bg-msil-blue hover:text-white rounded-lg"
              >
              <img src="assets/icons/template-menu-icon.svg" alt="" class="fas fa-regular "/>
              <a > Templates </a>
              <i class="fas fa-regular "  [ngClass]="{'fa-angle-down' : activeMenu=='template','fa-angle-right' : activeMenu!='template'}"></i>
            </div>
            <div *ngSwitchCase="'template'" class="mt-2 lg:w-[93%] 3xl:w-[92%] ml-auto  border-l-2 border-msil-blue pl-3">
              <!-- Dropdown menu items -->
              <div id="viewTemplates"  routerLink="template"  routerLinkActive="bg-msil-blue text-white"  [routerLinkActiveOptions]="{exact: true}"
                class="bg-[#F1F2FF] p-3 text-msibg-msil-blue hover:bg-msil-blue hover:text-white cursor-pointer rounded-lg">
                <a class="w-full gap-3 flex items-center whitespace-nowrap bg-transparent text-sm font-semibold active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                  >
                  
                  <i class="fas fa-regular fa-eye"></i>
                  View Templates
                </a>
              </div>
              <div routerLink="template/upload-history"  routerLinkActive="bg-msil-blue text-white"   
                class="bg-[#F1F2FF] px-2 py-3 mt-2 text-msibg-msil-blue hover:bg-msil-blue hover:text-white cursor-pointer rounded-lg">
                <a class="w-full gap-1 flex items-center whitespace-nowrap bg-transparent text-sm font-semibold active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                  >
                  <i class="fas fa-sharp fa-light fa-file-circle-plus"></i>
                  Upload History
                </a>
              </div>
            </div>
          </span>
         </ng-container>
       </div>
    </aside>


