import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';


export interface UserApi {
  users: Users[];
  inlineCount: number;
  totalPage:number;
}

export interface Users {
  created_at: string;
  number: string;
  state: string;
  title: string;
}
import { Observable, of } from 'rxjs';
import { api_endpoints, user_base_url } from 'src/app/api/api';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  // baseurl = user_base_url;
  constructor(private _httpClient: HttpClient) {}
  getAdminUsers(filter:any): Observable<any>{
    return this._httpClient.post(api_endpoints.user.admin_users,{filter});
  }
  
  getUsers(sort: string, order: any, page: number,count:number,filter:any): Observable<UserApi> {
    const requestUrl = `${api_endpoints.user.user_list}?count=${count}&sort=${sort}&order=${order}&page=${
      page 
    }`;
    return this._httpClient.post<UserApi>(requestUrl,{"filter":filter});
  }
  getUserById(id:any):Observable<any>{
    return this._httpClient.get(`${user_base_url}/${id}`);
  }
  updateUser(data:any,id:any):Observable<any>{
    return this._httpClient.put(`${user_base_url}/${id}`,data);
  }
  createUser(data:any):Observable<any>{
    return this._httpClient.post(`${user_base_url}/`,data);
  }
  addUserByBulkUpload(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();    
    if(file)
    formData.append('templatefile', file);

    return this._httpClient.post(api_endpoints.upload.add_bulk_user_upload, formData,{
      observe:'events',
      reportProgress: true,
    });
  }
  changeProfileImage(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();    
    if(file)
    formData.append('profileImage', file);

    return this._httpClient.post(api_endpoints.upload.change_profile_image, formData,{
      observe:'events',
      reportProgress: true,
    });
  }
}
