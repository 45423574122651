import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  snackBar$: BehaviorSubject<any>;
  constructor() {
    this.snackBar$ = new BehaviorSubject<any>({
      message: '',
      action:'',
    });
  }

  openSnackBar(message: string, action: string = 'ok') {
    // console.log('openSnackBar',{message});
    this.snackBar$.next({message,action});    
  }
}
