

  
  <ng-container  *ngIf="_authService.isLoggedIn()"> 
    <nav class="bg-white p-4 fixed top-0 w-full">
      <app-progress-bar></app-progress-bar>
        <div class="flex items-center justify-between z-50">
          <!-- Logo on the left -->
          <!-- <a routerLink="." class="text-white text-lg font-bold">
            <img src="assets/core-images/logo.png" alt="Logo" />
          </a> -->
          <a routerLink="" class="text-white text-lg font-bold flex items-center gap-1">
            <div><img src="assets/core-images/logo.png" alt="Logo" width="170px" height="20px" /></div>
            <div><img src="assets/core-images/Collate.svg" alt="Logo" width="55px" /></div>
            <div><img src="assets/core-images/X-logo.svg" alt="Logo" width="25px" /></div>
          </a>
          <!-- Navigation links on the right -->
          <ul class="flex items-center pr-3">
            <li class="relative mr-1">
              <img src="assets/core-images/powered_logo.png" alt="" class="w-32 mr-4" />
            </li>
            <li class="relative mr-1">
              <a class="hidden-arrow mr-4 flex items-center text-neutral-600 transition duration-200 hover:text-neutral-700 hover:ease-in-out focus:text-neutral-700 disabled:text-black/30 motion-reduce:transition-none dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300 [&.active]:text-black/90 dark:[&.active]:text-neutral-400"
                href="#" id="dropdownMenuButton1" role="button" onclick="toggleNotificationDropdown()"
                aria-expanded="false">
                <!-- Dropdown trigger icon -->
                <!-- <i class="fas fa-light fa-bell-ring fa-lg"></i> -->
                <img src="assets/icons/notification-icon.svg" alt="" class="w-10" />
    
                <!-- <iconify-icon icon="clarity:notification-solid"
                                style="color: white; font-size: 26px"></iconify-icon> -->
                <!-- Notification counter -->
                <span
                  class="absolute -mt-4 ml-2.5 rounded-full bg-danger px-[0.35em] py-[0.15em] text-[0.6rem] font-bold leading-none text-white">
                </span>
              </a>
              <!-- Dropdown menu -->
              <ul
                class="absolute z-[1000] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base dark:bg-neutral-700 [&[data-te-dropdown-show]]:block"
                aria-labelledby="dropdownMenuButton1" id="notificationDropdown" style="left: auto; right: 50%; top: 40px">
                <!-- Dropdown menu items -->
                <li>
                  <a class="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                    href="#" data-te-dropdown-item-ref>
                    Action
                  </a>
                </li>
                <li>
                  <a class="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                    href="#" data-te-dropdown-item-ref>
                    Another action
                  </a>
                </li>
                <li>
                  <a class="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                    href="#" data-te-dropdown-item-ref>
                    Something else here
                  </a>
                </li>
              </ul>
            </li>
            <li class="relative mr-4">
              <a class="hidden-arrow flex justify-center items-center text-neutral-600 transition duration-200 rounded-full hover:text-neutral-700 hover:ease-in-out focus:text-neutral-700 disabled:text-black/30 motion-reduce:transition-none dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300 [&.active]:text-black/90 dark:[&.active]:text-neutral-400"
              routerLink="/profile" aria-expanded="false">
                <!-- Dropdown trigger icon -->
                <!-- <i class="fas fa-thin fa-circle-user fa-xl w-10 h-10"></i> -->
                <img src="assets/icons/user-icon.svg" alt="" class="w-10" />
    
                <span
                  class="absolute -mt-4 ml-2.5 rounded-full bg-danger px-[0.35em] py-[0.15em] text-[0.6rem] font-bold leading-none text-white">
                </span>
              </a>
              <!-- Dropdown menu -->
              <!-- <ul class="absolute z-[1000] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base  dark:bg-neutral-700 [&[data-te-dropdown-show]]:block"
                            aria-labelledby="dropdownMenuButton2" id="userDropdown"
                            style="left: auto; right: 0; top: 40px;">
                            
                            <li>
                                <a class="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                                    href="#" id="changepassword" data-te-dropdown-item-ref
                                    onclick="changeColor('changepassword')">
                                    Change Password
                                </a>
                            </li>
                            <li>
                                <a class="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                                    href="./login/login.html" data-te-dropdown-item-ref>
                                    Logout
                                </a>
                            </li>
                        </ul> -->
            </li>
          </ul>
        </div>
    </nav>
      <div class="flex h-screen">
        <!-- Sidebar  z-10  -->
        
       <app-side-bar></app-side-bar>
    
        <!-- Your page content goes here mr-4-->
        <div
          class="flex-1 main-container overflow-x-auto p-4  bg-[#F5F5F5] rounded-3xl border-1 border-black  lg:mt-[6%] mb-[1%] ">
          <router-outlet></router-outlet>
        </div>
      </div>
  </ng-container>
  <ng-container  *ngIf="!_authService.isLoggedIn()"> 
    <router-outlet></router-outlet>
  </ng-container>
 