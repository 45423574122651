<div class="bg-white rounded-2xl py-4 mt-4">
    <!-- <p class="font-semibold text-2xl text-[#2D3393] pl-4 pb-4">
      Error Logs: Template Upload
    </p> -->
    <div class="flex justify-between items-center">
        <p class="font-semibold text-2xl text-[#2D3393] pl-4 pb-4">Error Logs: {{(this._authService.role()==="Business Admin")?'Template Approval':'Template Upload'}}</p>
        <button
          class="bg-[#2D3393] px-5 py-2 text-white rounded-3xl"
          routerLink="/template/upload-error-log"
        >
          View All
        </button>
      </div>
      <!-- <div class="flex flex-wrap gap-4 mt-7"> -->
    <div class="rounded-2xl overflow-hidden">
      <table class="table-auto w-full p-4 rounded-lg" *ngIf="data.length; else no_data">
        <thead class="bg-[#2D3393] text-white" >
          <tr>
            <th class="p-2">Date</th>
            <th class="p-2">Template Name</th>
            <th class="p-2">Error Type</th>
            <th class="p-2">Details</th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-center" *ngFor="let item of data;  let i=index;" [ngClass]="{'bg-[#E7E9FF]' : (i%2)==0}">
            <td class="p-2">{{item.createdAt| date:"dd/MM/YYYY 'at' h:mm:ss a":'GMT'}}</td>
            <td class="p-2">{{(item.templateName||item.template.templateName)| titlecase}}</td>
            <td class="p-2">{{item.error_type| titlecase}}</td>            
            <td class="p-2" [routerLink]="['/template/upload-error-log',item._id]"><button
              class="bg-[#2D3393] text-white rounded-3xl px-6 py-1">View</button></td>
          </tr>
        </tbody>
      </table>
      
      <ng-template #no_data><div class="m-2 p-4 rounded-lg bg-[#E7E9FF]">No data...</div></ng-template>
    </div>
  </div>