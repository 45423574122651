

<div class="flex h-screen bg-gray-100">
  <div class="w-1/2 p-8 bg-white" >
    <div class="flex items-center gap-2">
      <img src="assets/core-images/logo.png" alt="" />
    </div>
    
    <img src="assets/bg/login-page-img/login.svg" class="px-20 py-5" 
    alt=""> 
  </div>
  <div class="w-1/2 bg-cover bg-center" >
    <div class="flex flex-row-reverse bg-white p-4">
      <img src="assets/core-images/powered_logo.png" class="" alt="" />
    </div>
    <div class="flex items-center justify-center">
      <div class="w-3/4 h-3/4 flex-col gap-4 mt-10">
       
        <div>
          
          <div class="flex items-center gap-2">
            <img src="assets/core-images/Collate.svg" alt="" width="93px" />
            <img src="assets/core-images/X-logo.svg" alt="" width="28px" />
          </div>
          <div class="w-full mt-4">
            
            <!-- <h1
              class="text-[#2D3393] font-bold text-3xl my-2"              
            >
              Third Party Access Application
            </h1> -->
            <h1
              class="text-2xl text-black font-semibold my-2"
            >
              Login to your account
            </h1>
            <h3 class="font-thin my-2">
              Enter your credential to access your account
            </h3>
          </div>
          
          <div *ngIf="error" class="p-4 my-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" >
            {{ error }}
          </div>
          <form [formGroup]="loginForm" (submit)="login()">
            <div class="mt-6">
              <div class="flex flex-col gap-2">
                <label for="email" class="font-semibold">Email ID</label>
                <input id="email" autocomplete="false"
                  type="email"
                  class="border rounded-2xl p-3 outline-none"
                  placeholder="Ex. xyz@xyz.com" formControlName="email" required
                />
                <div *ngIf="f['email'].invalid && (f['email'].dirty || f['email'].touched)"  class="text-red-600">
                                    
                  <span *ngIf="f['email'].errors?.['required']">Email is <strong>required</strong></span>
                  <span *ngIf="!f['email'].errors?.['required']"><strong>Invalid</strong> Email</span>
                  
                  
                </div>
              </div>

              <div class="mt-4">
                <label for="password" class="font-semibold">Password</label>
                <div
                  class="form-input border rounded-2xl p-3 flex justify-between items-center mt-2 bg-white"
                >
                  <input id="password" autocomplete="false"
                  [type]="showPassword?'text':'password'"  placeholder="Ex. ********" formControlName="password"  required
                    class="bg-transparent outline-none w-full"
                    
                  />
                  <i (click)="togglePasswordVisibility()"
                    class="cursor-pointer fas fa-regular " [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'"
                    style="color: #2d3393"
                  ></i>
                </div>
                
                <div *ngIf="f['password'].invalid && (f['password'].dirty || f['password'].touched)" class="text-red-600">

                  <span *ngIf="f['password'].errors?.['required']">Password is <strong>required</strong></span>
                  <span *ngIf="f['password'].errors?.['minlength']">Password must be at least 6 characters</span>
                  <span *ngIf="f['password'].errors?.['maxlength']">Password must not exceed 20 characters</span>
                  
                  <span *ngIf="!f['password'].errors?.['minlength']&&!f['password'].errors?.['maxlength']&&f['password'].errors?.['pattern']">At least 1 letter, 1 number and 1 special character </span>
                  
                </div>
              </div>
              <div class="mt-4 flex items-center justify-between">
                <!--  class="cursor-pointer bg-msil-blue text-white px-5 py-3 rounded-3xl flex items-center justify-between w-1/2"
                   -->
                <button
                  type="submit"
                 class="cursor-pointer bg-black text-white px-5 py-3 rounded-3xl flex items-center justify-between w-3/4 mt-2"
                     
                >
                  <span>SIGN IN</span>
                  <i
                    class="fas fa-sharp fa-regular fa-arrow-right"
                    style="color: #ffffff"
                  ></i>
                </button>

              </div>
              
              <div class="mt-4 flex items-center justify-between">
                <a routerLink="/forgot-password" class="text-msil-blue">Forgot Password?</a>
              </div>
            </div>
          </form>        
        </div>
      </div>
    </div>
  </div>
</div>