import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.css']
})
export class LogoComponent {
  
  // @Input() width!: string;
  // @Input() height!: string;
  // @Input() whitebg!: boolean;
  // @HostBinding('style.width') get w() {
  // return this.width ? this.width : '';
  // }
  // @HostBinding('style.height') get h() {
  // return this.height ? this.height : '';
  // }

}
