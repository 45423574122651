import { Component } from '@angular/core';
import { TemplateService } from 'src/app/modules/template/services/template.service';
import { AuthService } from 'src/app/services/auth.service';
import * as moment from 'moment';

@Component({
  selector: 'app-template-to-be-filed',
  templateUrl: './template-to-be-filed.component.html',
  styleUrls: ['./template-to-be-filed.component.css']
})
export class TemplateToBeFiledComponent {
  user:any;
  data:any=[];
  constructor(public _authService: AuthService,
    private _templateService: TemplateService) {
      this.load();
      // const _user=_authService.getLoginUser(); 
      // if(_user){
      //   this.user=_user;
      // }
    // this.activePage =1;
  }
  getdiff(date:any){
    var date1 = moment(new Date(date));
    var date2 = moment.now();
    var days = date1.diff(date2, 'days') 
    return days;
  }
  load() {
    this._templateService!.getTemplatesToBeFiled().subscribe({
      next: (result: any) => {
        this.data = result.templates;
      },
      error: (err: any) => {
        this.data = [];
      }
    });
  }
}
