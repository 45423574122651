import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { LogoComponent } from './components/logo/logo.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ProfileComponent } from './components/profile/profile.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthInterceptor } from './auth.interceptor';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { UserHomeComponent } from './components/user-home/user-home.component';
import { AdminHomeComponent } from './components/admin-home/admin-home.component';
import { SuperAdminHomeComponent } from './components/super-admin-home/super-admin-home.component';
import { TemplateToBeFiledComponent } from './components/template-to-be-filed/template-to-be-filed.component';
import { ErrorLogsComponent } from './components/error-logs/error-logs.component';
import { SubmissionStatusComponent } from './components/submission-status/submission-status.component';
import { UploadSummaryComponent } from './components/upload-summary/upload-summary.component';
import { PaginationModule } from './modules/pagination/pagination.module';
import { DirectivesModule } from './directive/directives.module';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    LogoComponent,
    ProfileComponent,
    PageNotFoundComponent,
    ChangePasswordComponent,
    SideBarComponent,
    ProgressBarComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    UserHomeComponent,
    AdminHomeComponent,
    SuperAdminHomeComponent,
    TemplateToBeFiledComponent,
    ErrorLogsComponent,
    SubmissionStatusComponent,
    UploadSummaryComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    
    HttpClientModule,
    // MatDividerModule,MatButtonModule, MatMenuModule,MatToolbarModule, MatIconModule,
    // MatTableModule, MatPaginatorModule,
    // MatSortModule,
    // MatSidenavModule,
    // MatListModule,
    ReactiveFormsModule,
    FormsModule,
    FontAwesomeModule,
    BrowserAnimationsModule, 
    // MatFormFieldModule,
    //   MatCardModule,
    //   MatInputModule,
       MatProgressBarModule,
      //  MatGridListModule,

    //   MatDialogModule,MatTabsModule,
    //   MatSelectModule,MatButtonToggleModule,
    //   MatNativeDateModule,MatDatepickerModule,
    PaginationModule,DirectivesModule
  ],
  providers: [MatSnackBar,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
