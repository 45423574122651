import { Injectable } from '@angular/core';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpEventType
} from '@angular/common/http';
import { Observable, catchError, finalize, last, map, of, throwError } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { ProgressBarService } from 'src/app/services/progress-bar.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private _authService: AuthService,private _progressBarService:ProgressBarService) {}

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    // console.log("http Error");
    
      // this.hideProgressBar();
      //handle your auth error or rethrow
      if (err.status === 401 ) {
        //|| err.status === 403
          //navigate /delete cookies or whatever
          this._authService.logout('Session expired!');
          // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
          return of(err.message); // or EMPTY may be appropriate here
      }
      return throwError(()=>err);
  }
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    //  console.log({request});
    let contentType=undefined;
    if (request.headers.has('Content-Type'))
       contentType = request.headers.get('Content-Type');
      // console.log({contentType});
      
    const authReq = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${this._authService.getToken()}`)
    });
    // const authReq = request.clone({
    //   setHeaders: {
    //     'Content-Type' : contentType?contentType:'application/json; charset=utf-8',
    //     'Accept'       : 'application/json',
    //     'Authorization': `Bearer ${this._authService.getToken()}`,
    //   },
    // });
    
    this.showProgressBar();
    
    return next.handle(authReq).pipe(
      // map(event => this.getEventMessage(event)),
      // // tap(message => this.showProgress(message)),
      // last(),
      catchError(x=> this.handleAuthError(x)),
      finalize(() => this.hideProgressBar()) 
      );
  }
  
  private showProgressBar(){
    // this._progressBarService.progressBarPercentDone$.next(1);
    this._progressBarService.show();
  }
  private hideProgressBar(){
    this._progressBarService.progressBarPercentDone$.next(100);
    this._progressBarService.hide();
  }

  private getEventMessage(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.Sent:
        // console.log(`Uploading.`);
        
        return ;
  
      case HttpEventType.UploadProgress:
        // Compute and show the % done:
        const percentDone = event.total ? Math.round(100 * event.loaded / event.total) : 0;
        this._progressBarService.progressBarPercentDone$.next(percentDone);
        // console.log(`${percentDone}% uploaded.`);
        
        return ;
  
  
      case HttpEventType.DownloadProgress:
        // Compute and show the % done:
        const downloadPercentDone = event.total ? Math.round(100 * event.loaded / event.total) : 0;
        this._progressBarService.progressBarPercentDone$.next(downloadPercentDone);
        // console.log(`${downloadPercentDone}% download.`);
        
        return ;

      case HttpEventType.Response:
        // console.log(`completely uploaded!`);
        
        return ;
  
      default:
        // console.log(`surprising upload event: ${event.type}.`);
        
        return ;
    }
  }
}
