
<div class="mt-6" *ngIf="pages.length>1">
    <!-- Centered Pagination -->
    <div class="flex justify-center items-center mt-4">
        <button class=" px-3 py-1 " [ngClass]="{'bg-[#D5D6E9]  rounded-md text-[#686868]' : ((pages[0]||-1)==activePage)}" (click)="onClickPage(activePage - 1)"><i class="fas fa-solid fa-angle-left"></i></button>
        <!-- <span class="mx-2">1</span>
        <span class="mx-2"><button class="bg-[#2D3393] px-3 py-1 rounded-md text-white">2</button></span>
        <span class="mx-2">3</span>
        <span class="mx-2">...</span>
        <span class="mx-2">12</span> -->
        <ng-container *ngFor="let item of pages">

            <span class="mx-2"   *ngIf="activePage == item"
            (click)="onClickPage(item)">
            <button class="bg-[#2D3393] px-3 py-1 rounded-md text-white" >{{item}}</button></span>
            
            <span class="mx-2 cursor-pointer"  *ngIf="activePage != item" 
            (click)="onClickPage(item)">{{item}}</span>
        </ng-container>
        <button [ngClass]="{'bg-[#D5D6E9]  rounded-md text-[#686868]' : ((pages[pages.length-1]||-1)==activePage)}" class=" px-3 py-1" (click)="onClickPage(activePage + 1)"><i
                class="fas fa-solid fa-angle-right"></i></button>
    </div>
</div>