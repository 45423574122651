const uat_base_url="http://3.109.169.21:2032/api/";
const local_base_url="http://localhost:3000/api/";
const prod_base_url="/api/";
// const prod_base_url="http://10.212.195.132:3000/api/";
//"http://marutisuzukicollatex.com:3000/api/";

export const base_url=prod_base_url;

export const user_base_url=`${base_url}users`;
export const upload_base_url=`${base_url}upload`;
export const template_base_url=`${base_url}template`;
export const reporting_base_url=`${base_url}reporting`;

export const api_endpoints={
  user:{
    authenticate:`${user_base_url}/authenticate`,
    password_reset_request:`${user_base_url}/req-reset-password`,
    valid_password_token:`${user_base_url}/valid-password-token`,
    reset_password:`${user_base_url}/reset-password`,
    change_password:`${user_base_url}/change-password`,
    update_profile:`${user_base_url}/update-profile`,
    admin_users:`${user_base_url}/admin-users`,
    user_list:`${user_base_url}/list`,
    template_bulk_user_upload:`${user_base_url}/download/template-bulk-user-upload`,
  },
  
  upload:{
    add_template:`${upload_base_url}/template`,
    add_fill_template:`${upload_base_url}/fill-template`,
    add_sa_remarks:`${upload_base_url}/add-template-remarks`,
    add_user_remarks:`${upload_base_url}/add-remarks`,    
    add_bulk_user_upload:`${upload_base_url}/bulk-user-upload`,
    change_profile_image:`${upload_base_url}/change-profile-image`,
  },

  template:{
    upload_summary:`${template_base_url}/admin-upload-summary`,
    submission_status:`${template_base_url}/submission-status`,
    to_be_filed:`${template_base_url}/to-be-filed`,
    admin_error_log:`${template_base_url}/error-log`,
    user_error_log:`${template_base_url}/fill/error-log`,
    details:`${template_base_url}/details`,
    create:`${template_base_url}/add`,
    user_log:`${template_base_url}/user-logs`,
    action:`${template_base_url}/action`,
    share:`${template_base_url}/share-with-user`,
    fill:`${template_base_url}/fill`,
    user_remarks:`${template_base_url}/fill/remarks`,
    download:`${template_base_url}/download`,
    download_user_fill:`${template_base_url}/fill/download`,
    remarks_download:`${template_base_url}/remarks-download`,
    user_remarks_download:`${template_base_url}/fill/remarks-download`,
    log_download:`${template_base_url}/log-download`,
  },

  reporting:{      
    consolidated:`${reporting_base_url}/template`,
    sa_home:`${reporting_base_url}/sa-home`,
    ba_home:`${reporting_base_url}/ba-home`,
    user_home:`${reporting_base_url}/user-home`,
  }
}