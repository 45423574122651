import { Component } from '@angular/core';
import { TemplateService } from 'src/app/modules/template/services/template.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-submission-status',
  templateUrl: './submission-status.component.html',
  styleUrls: ['./submission-status.component.css']
})
export class SubmissionStatusComponent {
  user:any;
  data:any=[];
  constructor(public _authService: AuthService,
    private _templateService: TemplateService) {
      this.load();
      // const _user=_authService.getLoginUser(); 
      // if(_user){
      //   this.user=_user;
      // }
    // this.activePage =1;
  }
  load() {
    this._templateService!.getSubmissionStatus().subscribe({
      next: (result: any) => {
        this.data = result.templates;
      },
      error: (err: any) => {
        this.data = [];
      }
    });
  }
}
