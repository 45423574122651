
<div class="bg-gray-100 flex items-center justify-center">
    <div class="bg-white p-6 rounded-3xl flex-col w-2/4">
      <h2 class="text-2xl font-semibold mb-6 text-center text-blue-900">
        Change Password
      </h2>
      
      <div *ngIf="error" class="p-4 my-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" >
            {{ error }}
      </div>
      <div  *ngIf="suceess" class="p-4 my-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400">                    
            {{ suceess }}
      </div>  
      <form [formGroup]="changePasswordForm" (ngSubmit)="onFormSubmit(formDirective)" #formDirective="ngForm">
        <!-- Current Password -->
        <div class="mb-4">
          <label for="currentpassword" class="text-[#7A7A7A]">Current Password</label>
          <div class="form-input bg-[#F1F1FF] p-3 rounded-lg flex justify-between items-center mt-4" >
            <input autocomplete="false" id="currentpassword" appTrimText  matInput [type]="showCurrentPassword?'text':'password'" class="bg-transparent outline-none w-full" minlength="6" pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,20}$" maxlength="20" placeholder="Ex. ********" formControlName="currentpassword"  required >

            <i (click)="toggleCurrentPasswordVisibility()"
            class="cursor-pointer fas fa-regular " [ngClass]="showCurrentPassword ? 'fa-eye-slash' : 'fa-eye'"
            style="color: #2d3393"
            ></i>
          </div>
          <div class="text-red-600"  *ngIf="f['currentpassword'].invalid && (f['currentpassword'].dirty || f['currentpassword'].touched)">

              <span *ngIf="f['currentpassword'].errors?.['required']">Current Password is <strong>required</strong></span>
              <span *ngIf="f['currentpassword'].errors?.['minlength']">Current Password must be at least 6 characters</span>
              <span *ngIf="f['currentpassword'].errors?.['maxlength']">Current Password must not exceed 20 characters</span>
              
              <span *ngIf="!f['currentpassword'].errors?.['minlength']&&!f['currentpassword'].errors?.['maxlength']&&f['currentpassword'].errors?.['pattern']">At least 1 letter, 1 number and 1 special character </span>
              
          </div>
        </div>

        <!-- New Password -->
        <div class="mb-4">
          
          <label for="password" class="text-[#7A7A7A]">New Password</label>
          <div class="form-input bg-[#F1F1FF] p-3 rounded-lg flex justify-between items-center mt-4" >
              <input autocomplete="false" id="password" appTrimText  matInput [type]="showPassword?'text':'password'" class="bg-transparent outline-none w-full" minlength="6" pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,20}$" maxlength="20" placeholder="Ex. ********" formControlName="password"  required >

              <i (click)="togglePasswordVisibility()"
              class="cursor-pointer fas fa-regular " [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'"
              style="color: #2d3393"
              ></i>
          </div>
          <div class="text-red-600"  *ngIf="f['password'].invalid && (f['password'].dirty || f['password'].touched)">

            <span *ngIf="f['password'].errors?.['required']">New Password is <strong>required</strong></span>
            <span *ngIf="f['password'].errors?.['minlength']">New Password must be at least 6 characters</span>
            <span *ngIf="f['password'].errors?.['maxlength']">New Password must not exceed 20 characters</span>
            <span *ngIf="f['password'].errors?.['passwordSameAsOldMatch']">New Password can't be same as same <strong>Current Password</strong></span>
            
            <span *ngIf="!f['password'].errors?.['minlength']&&!f['password'].errors?.['maxlength']&&f['password'].errors?.['pattern']">At least 1 letter, 1 number and 1 special character </span>
            
          </div>
        </div>

        <!-- Confirm Password -->
        <div class="mb-6">
          <label for="confirmpassword" class="text-[#7A7A7A]">Confirm Password</label>
          <div class="form-input bg-[#F1F1FF] p-3 rounded-lg flex justify-between items-center mt-4">
              <input autocomplete="false" id="confirmpassword" appTrimText matInput [type]="showConfirmPassword?'text':'password'" class="bg-transparent outline-none w-full" minlength="6" pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,20}$" maxlength="20" placeholder="Ex. ********" formControlName="confirmpassword"  required >

              <i (click)="toggleConfirmPasswordVisibility()"
              class="cursor-pointer fas fa-regular " [ngClass]="showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'"
              style="color: #2d3393"
              ></i>
          </div>
          <div class="text-red-600"  *ngIf="f['confirmpassword'].invalid && (f['confirmpassword'].dirty || f['confirmpassword'].touched)">

              <span *ngIf="f['confirmpassword'].errors?.['required']">Confirm Password is <strong>required</strong></span>
              <span *ngIf="f['confirmpassword'].errors?.['minlength']">Confirm Password must be at least 6 characters</span>
              <span *ngIf="f['confirmpassword'].errors?.['maxlength']">Confirm Password must not exceed 20 characters</span>
              <span *ngIf="f['confirmpassword'].errors?.['passwordNotMatch']">Confirm Password is not match with <strong>Password</strong></span>
              
              <span *ngIf="!f['confirmpassword'].errors?.['minlength']&&!f['confirmpassword'].errors?.['maxlength']&&f['confirmpassword'].errors?.['pattern']">At least 1 letter, 1 number and 1 special character </span>
                
            </div>
        </div>

        <!-- Change Password Buttons -->
        <div class="flex justify-center">
          <button routerLink="/profile" class="text-blue-900 rounded-2xl py-3 px-12 focus:outline-none border-blue-800 border mr-4"

            type="button">
            Cancel
          </button>
          <button
            class="bg-blue-900 text-white py-3 px-12 rounded-2xl focus:outline-none focus:shadow-outline-gray active:bg-gray-800"
            [ngClass]="{'opacity-50 cursor-not-allowed' : !changePasswordForm.valid}" 
            type="submit"  [disabled]="!changePasswordForm.valid">
            Update
          </button>
        </div>
      </form>
    </div>
  </div>