import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrimTextDirective } from './trim-text.directive';



@NgModule({
  imports: [
    CommonModule
  ],
  
  declarations: [TrimTextDirective],
  exports: [TrimTextDirective]
})
export class DirectivesModule { }
