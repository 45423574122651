import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { Observable, of as observableOf, Subject, catchError, debounceTime, distinctUntilChanged, map, merge, startWith, switchMap } from 'rxjs';
import { api_endpoints } from 'src/app/api/api';
import { UserService } from 'src/app/modules/user/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent {
  
  canSubmit=true;
  selectedFile?: File | null;
  currentFile?: File;
  progress = 0;
  message = '';
  // fileInfos?: Observable<any>;
  // destination="";
  error:string|null=null;
  user!:any;
  constructor(public _authService:AuthService,
    private _userService:UserService,
    private _snackBarService:SnackBarService)
  {
    const _user=_authService.getLoginUser(); 
    if(_user){
      this.user=_user;
    }
  }
  changeProfileImage() {
    this.progress = 0;
    this.canSubmit=false;
    const file: File | null = this.selectedFile||null;
    this.currentFile = file||undefined;
    
      
    if(this.currentFile){
      this._userService.changeProfileImage(this.currentFile).subscribe(
        (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progress = Math.round(100 * event.loaded / event.total);
          } else if (event instanceof HttpResponse) {

            this.canSubmit=true;
            this.message = event.body.message;
            
            this.error="";
            this.selectedFile=null;
            this.user=event.body.userInfo;
            this._authService.updateLoginUser(this.user);          
            this._snackBarService.openSnackBar(this.message); 
          }
        },
        (err: any) => {
          console.log(err);
          this.progress = 0;
          this.canSubmit=true;

          if (err.error && err.error.message) {
            this.message ='';
           this.error= err.error.message;
          } else {
            this.message ='';
            this.error= 'Could not add remark!';
          }

          this.currentFile = undefined;
        });
    }
    
  }
  selectFile(event: any): void {
    const _files=event.target.files ?? null;
    if(_files && _files.length==1){
      this.error="";
      this.selectedFile = _files[0] ?? null;
      this.changeProfileImage();
    }else{      
      
      this.error="Please select one file at time.";
      this.selectedFile = undefined;
    }
  }
  
}
