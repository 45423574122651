import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ProgressBarService } from 'src/app/services/progress-bar.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent {
  forgotForm: FormGroup;  
  error:string|null=null;
  submitted:boolean=false;

  constructor(
    private _fb: FormBuilder,
    private _authService:AuthService,
    
    public _progressBarService:ProgressBarService,
    private router:Router){
      this.forgotForm = this._fb.group({        
        email: ['',[Validators.email]],
      });
    }
    
  
    get f(): { [key: string]: AbstractControl } {
      return this.forgotForm.controls;
    }
  onFormSubmit() {
    this._progressBarService.show();
    this._authService.forgotPassword(this.forgotForm.value).subscribe({next:(result:any)=>{
      this.submitted=true;
      this.forgotForm.reset();
      this.error="";
      // this._progressBarService.hide();
    },
    error:(err:any)=>{        
      console.log(err);
      // this._progressBarService.hide();
      
      // return throwError(()=>new Error('Product credentials are invalid.'));
      this.error=err.error.message||'Invalid Details';
    }});   
    
  }
  
}
