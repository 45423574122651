
<div class="flex justify-between items-center">
    <p class="font-semibold text-2xl">Templates to be Filled</p>
    <button
      class="bg-[#2D3393] px-5 py-2 text-white rounded-3xl"
      id="viewTemplates"
      routerLink="/template"
    >
      View All Templates
    </button>
  </div>
  <div class="flex flex-wrap gap-4 mt-7" *ngIf="data.length; else no_data">
    <!-- Card 1 -->
    <div class="border-b   rounded-lg border "  *ngFor="let item of data; ; let i=index;">
      <table class="bg-white">
        <tbody>
          <tr class="border-b p-2">
            <td class="text-[#575757] font-medium p-2">Name</td>
            <td class="text-[#2D3393]  p-2">{{item.templateName| titlecase}}</td>
          </tr>
          <tr class="border-b p-2 bg-[#F5F5F5]">
            <td class="text-[#575757] font-medium p-2">Template ID</td>
            <td class="text-[#2D3393] p-2 ">{{item._id}}</td>
          </tr>
          <tr class="border-b p-2">
            <td class="text-[#575757] font-medium p-2">Due Date</td>
            <td class="text-[#2D3393] p-2 ">{{item.dueDate| date:'dd/MM/YYYY':'GMT'}}</td>
          </tr>
          <tr class="text-[#FF3939] bg-[#F5F5F5]">
            <td class="font-medium p-2">Remaining Days</td>
            <td class=" p-2" ><span *ngIf="getdiff(item.dueDate) as diff else todayBlock">
              <ng-container *ngIf="diff<0; else elseBlock">Expired</ng-container>               
              <ng-template #elseBlock>{{diff}} {{diff>1?'Days':'Day'}}</ng-template>
            </span>
            <ng-template #todayBlock>Today</ng-template></td>
          </tr>
          <tr class="">
            <td colspan="2" class="text-center">
              <button [routerLink]="['/template/details',item._id]"
                class="border border-[#2D3393] py-1 px-6 rounded-3xl text-[#2D3393] my-3"
              >
                View Details
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <div class="border-b grow bg-white rounded-lg border">
      <table class="w-full">
        <tbody>
          <tr class="border-b p-2">
            <td class="text-[#575757] font-medium p-2">Name</td>
            <td class="text-[#2D3393] font-semibold">AB12</td>
          </tr>
          <tr class="border-b p-2 bg-[#F5F5F5]">
            <td class="text-[#575757] font-medium p-2">Template ID</td>
            <td class="text-[#2D3393] font-semibold">1234</td>
          </tr>
          <tr class="border-b p-2">
            <td class="text-[#575757] font-medium p-2">Due Date</td>
            <td class="text-[#2D3393] font-semibold">12/08/2024</td>
          </tr>
          <tr class="text-[#FF3939] bg-[#F5F5F5]">
            <td class="font-medium p-2">Remaining Days</td>
            <td class="font-semibold">4</td>
          </tr>
          <tr class="">
            <td colspan="2" class="text-center">
              <button
                class="border border-[#2D3393] py-1 px-6 rounded-3xl text-[#2D3393] my-3"
              >
                View Details
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="border-b grow bg-white rounded-lg border">
      <table class="w-full">
        <tbody>
          <tr class="border-b p-2">
            <td class="text-[#575757] font-medium p-2">Name</td>
            <td class="text-[#2D3393] font-semibold">AB12</td>
          </tr>
          <tr class="border-b p-2 bg-[#F5F5F5]">
            <td class="text-[#575757] font-medium p-2">Template ID</td>
            <td class="text-[#2D3393] font-semibold">1234</td>
          </tr>
          <tr class="border-b p-2">
            <td class="text-[#575757] font-medium p-2">Due Date</td>
            <td class="text-[#2D3393] font-semibold">12/08/2024</td>
          </tr>
          <tr class="text-[#FF3939] bg-[#F5F5F5]">
            <td class="font-medium p-2">Remaining Days</td>
            <td class="font-semibold">4</td>
          </tr>
          <tr class="">
            <td colspan="2" class="text-center">
              <button
                class="border border-[#2D3393] py-1 px-6 rounded-3xl text-[#2D3393] my-3"
              >
                View Details
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="border-b grow bg-white rounded-lg border">
      <table class="w-full">
        <tbody>
          <tr class="border-b p-2">
            <td class="text-[#575757] font-medium p-2">Name</td>
            <td class="text-[#2D3393] font-semibold">AB12</td>
          </tr>
          <tr class="border-b p-2 bg-[#F5F5F5]">
            <td class="text-[#575757] font-medium p-2">Template ID</td>
            <td class="text-[#2D3393] font-semibold">1234</td>
          </tr>
          <tr class="border-b p-2">
            <td class="text-[#575757] font-medium p-2">Due Date</td>
            <td class="text-[#2D3393] font-semibold">12/08/2024</td>
          </tr>
          <tr class="text-[#FF3939] bg-[#F5F5F5]">
            <td class="font-medium p-2">Remaining Days</td>
            <td class="font-semibold">4</td>
          </tr>
          <tr class="">
            <td colspan="2" class="text-center">
              <button
                class="border border-[#2D3393] py-1 px-6 rounded-3xl text-[#2D3393] my-3"
              >
                View Details
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div> -->

    <!-- Repeat similar structure for Card 2 and Card 3 -->
  </div>
  
  <ng-template #no_data><div class="m-2 p-4 rounded-lg bg-[#E7E9FF]">No pending templates...</div></ng-template>