import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressBarService {
  public progressBar$ = new Subject<boolean>()
  public progressBarPercentDone$ = new Subject<Number>()
  constructor(){
    this.hide();
  }
  show(){
    this.progressBar$.next(true);
  }
  hide(){
    this.progressBar$.next(false);
  }
}
