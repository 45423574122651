

    
    <div class="mt-4 bg-white p-5 rounded-3xl flex py-3">
      <div class="flex items-center gap-4 border-r px-3 grow">
        <div
          class="bg-[#FFEBEB] w-[84px] h-[84px] rounded-full flex items-center justify-center"
        >
          <i
            class="fas fa-regular fa-stopwatch fa-2xl"
            style="color: #ff1c1c"
          ></i>
        </div>
        <div>
          <p class="text-[#ACACAC]">
            Templates <br />
            Due
          </p>
          <p class="font-semibold text-3xl">{{data.due_templates||0}}</p>
        </div>
      </div>
      <div class="flex items-center gap-4 border-r px-3 grow">
        <div
          class="bg-[#E1FFEF] w-[84px] h-[84px] rounded-full flex items-center justify-center"
        >
          <i
            class="fas fa-regular fa-circle-check fa-2xl"
            style="color: #00ac4f"
          ></i>
        </div>
        <div>
          <p class="text-[#ACACAC]">
            Templates <br />
            Submitted
          </p>
          <p class="font-semibold text-3xl">{{data.submitted_templates||0}}</p>
        </div>
      </div>
      <div class="flex items-center gap-4 border-r px-3 grow">
        <div
          class="bg-[#FFEBEB] w-[84px] h-[84px] rounded-full flex items-center justify-center"
        >
          <!-- <i
            class="fas fa-regular fa-circle-exclamation-check fa-2xl"
            style="color: #ff2e00"
          ></i> -->
          <img src="assets/icons/home-icon1.svg" alt="Templates Pending" class="w-auto h-auto" />
        </div>
        <div>
          <p class="text-[#ACACAC]">
            Templates <br />
            Issues Found
          </p>
          <p class="font-semibold text-3xl">{{data.issues_found_count||0}}</p>
        </div>
      </div>
      <div class="flex items-center gap-4 px-3 grow">
        <div
          class="bg-[#F5F2FF] w-[84px] h-[84px] rounded-full flex items-center justify-center"
        >
          <i class="fas fa-regular fa-message fa-2xl" style="color: #5932ea"></i>
        </div>
        <div>
          <p class="text-[#ACACAC]">
            Templates <br />
            Business Admin <br />
            Comments
          </p>
          <p class="font-semibold text-3xl">{{data.ba_remarks_count||0}}</p>
        </div>
      </div>
    </div>
    <div class="mt-6">
      <app-template-to-be-filed></app-template-to-be-filed>
      <app-error-logs></app-error-logs>
    </div>
    
    <!-- add pagination here -->
    <div class="mt-6">
      <!-- Centered Pagination -->
      <!-- <div class="flex justify-center items-center mt-4">
        <button class="px-5 py-3">
          <i class="fas fa-solid fa-angle-left"></i>
        </button>
        <span class="mx-2">1</span>
        <span class="mx-2"
          ><button class="bg-[#2D3393] px-3 py-1 rounded-md text-white">
            2
          </button></span
        >
        <span class="mx-2">3</span>
        <span class="mx-2">...</span>
        <span class="mx-2">12</span>
        <button class="bg-[#D5D6E9] px-3 py-1 rounded-md text-[#686868]">
          <i class="fas fa-solid fa-angle-right"></i>
        </button>
      </div> -->
    </div>