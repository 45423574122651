<div class="bg-white rounded-2xl py-4 mt-4">
    <div class="flex items-center justify-between">
      <p class="font-semibold text-2xl text-[#2D3393] pl-4 ">Submission Status</p>
      <div>
        <button 
        routerLink="/template" class="bg-white border border-[#2D3393] rounded-3xl px-6 py-1 mr-2 text-[#2D3393]">View
          List</button>
      </div>
    </div>
    <div class="rounded-2xl overflow-hidden mt-4">
      <table class="table-auto w-full p-4 rounded-lg" *ngIf="data.length; else no_data">
        <thead class="bg-[#2D3393] text-white">
          <tr>
            <th class="p-2">Template Name</th>
            <th class="p-2">Template ID</th>
            <th class="p-2">Shared To</th>
            <th class="p-2">Filled By</th>
            <th class="p-2">Due Date</th>
          </tr>
        </thead>
        <tbody  >
          <tr class="text-center " *ngFor="let item of data; ; let i=index;" [ngClass]="{'bg-[#E7E9FF]' : (i%2)==0}">
            <td class="p-2">{{item.templateName| titlecase}}</td>
            <td class="p-2">{{item._id}}</td>
            <td class="p-2">{{item.userList.length}}</td>
            <td class="p-2">{{item.filledByUsers.length}}</td>
            <td class="p-2">{{item.dueDate| date:'dd/MM/YYYY':'GMT'}}</td>
          </tr>
        </tbody>
      </table>
      
      <ng-template #no_data><div class="m-2 p-4 rounded-lg bg-[#E7E9FF]">No data...</div></ng-template>
    </div>
  </div>