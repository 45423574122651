import { Component } from '@angular/core';
import { TemplateService } from 'src/app/modules/template/services/template.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-error-logs',
  templateUrl: './error-logs.component.html',
  styleUrls: ['./error-logs.component.css']
})
export class ErrorLogsComponent {
  data: any[] = [];
  pageSize = 4;
  resultsLength = 0;
  activePage: number = 0;
  isRateLimitReached = false;
 
  user!:any;
  
  authToken:any;
  constructor(public _authService: AuthService,
    private _templateService: TemplateService ) {
      const _user=_authService.getLoginUser(); 
      if(_user){
        this.user=_user;
        this.authToken=_authService.getToken();
      }
      this.load();
      
    // this.activePage =1;
  }
  
  load() {
    this._templateService!.getTemplateErrorLogs(
      this._authService.role(),
      '_id',
      'desc',
      this.activePage,
      this.pageSize
    ).subscribe({
      next: (result: any) => {
        this.resultsLength = result.inlineCount;
        this.data = result.logs;
      },
      error: (err: any) => {
        this.data = [];
      }
    });
  }
}
