

<div class="flex h-screen bg-gray-100">
    <div class="w-1/2 p-8 bg-white">
      <div class="flex items-center gap-2">
        <img src="assets/core-images/logo.png" alt="" />
        <img src="assets/core-images/Collate.svg" alt="" width="63px" />
        <img src="assets/core-images/X-logo.svg" alt="" width="28px" />
      </div>
      <div class="flex items-center justify-center">
        <div class="w-3/4 h-3/4 flex-col gap-4 mt-10 "> 
            <div class="items-center justify-center">
                <div class="w-full mt-4 ">
                    <p class="font-semibold text-xl">Forgot Password</p> 
                </div>     
                <div *ngIf="error" class="p-4 my-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" >
                    {{ error }}
                </div> 
                <div  class="p-4 my-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"  *ngIf="this.submitted"> 
                        
                    <p>An email has been sent to your registered email address. Please check email for further process! <a routerLink="/forgot-password" (click)="this.submitted=false"><strong>Resend Link</strong></a></p>
                </div>  
                
            
                <form [formGroup]="forgotForm" (submit)="onFormSubmit()" *ngIf="!this.submitted">
                            
                    <div class="mt-6">
                        <div class="flex flex-col gap-2">
                            <label for="email" class="font-semibold">Email Id</label>
                            <input autocomplete="false"
                            id="email"
                            type="email"
                            class="border rounded-3xl p-3 outline-none"
                            placeholder="Ex. xyz@xyz.com" formControlName="email" required
                            />                        
                            <div class="text-red-600" *ngIf="f['email'].invalid && (f['email'].dirty || f['email'].touched)">                    
                                <span *ngIf="f['email'].errors?.['required']">Email is <strong>required</strong></span>                   
                                <span *ngIf="!f['email'].errors?.['required']">Email is not <strong>valid</strong></span>                   
                            </div>
                        </div>
        
                        <div class="mt-4 flex items-center justify-between">                        
                            <button
                                type="submit"   [disabled]="!forgotForm.valid"
                                class="cursor-pointer bg-msil-blue text-white px-5 py-3 rounded-3xl flex items-center justify-between w-1/2"
                                [ngClass]="{'opacity-50 cursor-not-allowed' : !forgotForm.valid}" 
                            >
                                <span>Next</span>
                                <i
                                class="fas fa-sharp fa-regular fa-arrow-right"
                                style="color: #ffffff"
                                ></i>
                            </button>
                            <a routerLink="/login" class="text-msil-blue">Login</a>
                        </div>
                    </div>
                </form>
            </div> 
            
        </div>
      </div>
    </div>
    <div
      class="w-1/2 bg-cover bg-center p-4"
      style="background-image: url(../../../assets/bg/login-page-img/pattern.png)"
    >
      <div class="flex flex-row-reverse">
        <img src="assets/core-images/powered_logo.png" class="" alt="" />
      </div>
      <img src="assets/bg/login-page-img/side-img1.svg" class="px-20 py-5" alt=""> 
    </div>
  </div>