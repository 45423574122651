import { Component } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, FormGroupDirective } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent {
  changePasswordForm: FormGroup;
  dealerError!:any;
  showPassword:boolean=false;
  showConfirmPassword:boolean=false;
  showCurrentPassword:boolean=false;
  
  constructor(
    private _fb: FormBuilder,
    private _authService:AuthService,
    
    public _snackBarService:SnackBarService,
    ){
      this.changePasswordForm = this._fb.group({  
        currentpassword: '',
        password: '',
        confirmpassword:''
        
      }, {
        validators: this.passwordAndConfirmPassword.bind(this)
      });
      // this.usePasswordToggle();
    }
    passwordAndConfirmPassword(changePasswordForm: FormGroup) {
      const currentpassword  = changePasswordForm.get('currentpassword')?.value;
      const password  = changePasswordForm.get('password')?.value;
      const confirmPassword = changePasswordForm.get('confirmpassword')?.value;
      if(currentpassword==password)
        changePasswordForm.get('password')?.setErrors({ 'passwordSameAsOldMatch': true });
      else if(password != confirmPassword)
      changePasswordForm.get('confirmpassword')?.setErrors({ 'passwordNotMatch': true });
    }
    get f(): { [key: string]: AbstractControl } {
      return this.changePasswordForm.controls;
    }
    toggleCurrentPasswordVisibility(): void {
      this.showCurrentPassword = !this.showCurrentPassword;
    }
    togglePasswordVisibility(): void {
      this.showPassword = !this.showPassword;
    }
    toggleConfirmPasswordVisibility(): void {
      this.showConfirmPassword = !this.showConfirmPassword;
    }
    ngOnInit() {
    
   }
  error:string|null=null;
  suceess:string|null=null;
  
  
  onFormSubmit(
    formDirective: FormGroupDirective) {
    
      
      this._authService.updateUserPassword(this.changePasswordForm.get('currentpassword')?.value,this.changePasswordForm.get('password')?.value).subscribe({next:(result:any)=>{
        // console.log('after update password',{result});  
        this.changePasswordForm.reset();
        formDirective.resetForm();

        this.error=null;
        // this.suceess=result.message||'Password Updated.';
        this._snackBarService.openSnackBar(result.message||'Profile Updated.', '');
      },
      error:(err:any)=>{          
        console.log(err);      
        this.suceess=null;
        this.error=err.error.message||'Something went wrong.';
      }});
   
       
    
  }
}
