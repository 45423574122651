
<div class="">
    <h2 class="text-[#3F3A8D] text-[32px] font-bold">Profile</h2>
    <div class="bg-white rounded-3xl p-2">
      <div class="flex justify-center flex-col items-center ">
        <div>
          <img [src]="user.profileImage?user.profileImage:'assets/core-images/Ellipse 39.jpg'" class="p-1 profile-image" width="125px" height="108px" />
        </div>
        <!-- Add the 'profile-image' class to apply the styles -->
        <p class="text-[#2D3393] text-2xl mt-4 cursor-pointer" (click)="fileInput.click()" >Change Profile Image</p>
        
          
        <input hidden  #fileInput type="file" (change)="selectFile($event)"  multiple="false" accept=".png, .jpg, .jpeg, .gif">
        <!-- <span class="file-name"> {{selectedFile?.name}}</span>   -->
      </div> 
      <div class="flex mt-10">
        <div class="w-1/2 flex flex-col gap-2">
          <label for="name" class="text-[#7A7A7A]">Name</label>
          <input autocomplete="false" id="name" type="text" class="bg-[#F1F1FF]  px-3 py-3 w-1/1 rounded-lg w-11/12" [value]="user.userName" readonly/>
        </div>
        <div class="w-1/2 flex flex-col gap-2">
          <label for="email" class="text-[#7A7A7A]">Email ID</label>
          <input autocomplete="false" id="email" type="text" class="bg-[#F1F1FF]  px-3 py-3 w-1/1 rounded-lg w-11/12"  [value]="user.email" readonly/>
        </div>
      </div>
      <div class="flex mt-6">
        <div class="w-1/2 flex flex-col gap-2">
          <label for="staff_id" class="text-[#7A7A7A]">Staff ID</label>
          <input autocomplete="false" id="staff_id" type="text" class="bg-[#F1F1FF]  px-3 py-3 w-1/1 rounded-lg w-11/12"  [value]="user.staffId" readonly/>
        </div>
        <div class="w-1/2 flex flex-col gap-2">
          <label for="organization" class="text-[#7A7A7A]">Organization</label>
          <input autocomplete="false" id="organization" type="text" class="bg-[#F1F1FF]  px-3 py-3 w-1/1 rounded-lg w-11/12"  [value]="user.organization" readonly/>
        </div>
      </div>
      <div class="flex mt-6">
        <div class="w-1/2 flex flex-col gap-2">
          <label for='department' class="text-[#7A7A7A]">Department</label>
          <input autocomplete="false" id='department' type="text" class="bg-[#F1F1FF]  px-3 py-3 w-1/1 rounded-lg w-11/12"  [value]="user.department" readonly/>
        </div>
        <div class="w-1/2 flex flex-col gap-2">
          <label for='role' class="text-[#7A7A7A]">Role</label>
          <input autocomplete="false" id='role' type="text" class="bg-[#F1F1FF]  px-3 py-3 w-1/1 rounded-lg w-11/12"  [value]="user.role" readonly/>
        </div>
      </div>

      <div class="flex justify-end mt-6 gap-3">
        <button routerLink="/change-password"
          class="border border-[#2D3393] py-2 px-6 rounded-3xl text-[#2D3393] my-3">
          Change Password
        </button>
        <button class="bg-[#2D3393] py-2 px-16 rounded-3xl text-white my-3 mr-5" (click)="_authService.logout()">
          Logout
        </button>
      </div>
    </div>
  </div>