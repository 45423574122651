import { Directive, ElementRef, HostListener} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appTrimText]',
})
export class TrimTextDirective {
  constructor(private el: ElementRef,private ctrl: NgControl) {}
  @HostListener('change') onChange() {
    this.ctrl?.control?.setValue(this.el.nativeElement.value?.trim());
  }
}
