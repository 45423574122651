import { Component } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap, catchError , of as observableOf} from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { ProgressBarService } from 'src/app/services/progress-bar.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent {
  showPassword:boolean=false;
  showConfirmPassword:boolean=false;
  showForm:boolean=false;
  resetPasswordForm: FormGroup;
  dealer!:any;
  dealerError!:any;
  token!:any
  constructor(
    private _fb: FormBuilder,
    private _router:Router,
    private _authService:AuthService,
    public _progressBarService:ProgressBarService,
    
    public _snackBarService:SnackBarService,
    private _route: ActivatedRoute,
    ){
      
      this.resetPasswordForm = this._fb.group({  
        password: '',
        confirmpassword:''
        
      }, {
        validators: this.passwordAndConfirmPassword.bind(this)
      });
      // this.usePasswordToggle();
    }
    passwordAndConfirmPassword(resetPasswordForm: FormGroup) {
      const password  = resetPasswordForm.get('password')?.value;
      const confirmPassword = resetPasswordForm.get('confirmpassword')?.value;
      if(password != confirmPassword)
      resetPasswordForm.get('confirmpassword')?.setErrors({ 'passwordNotMatch': true });
    }
    get f(): { [key: string]: AbstractControl } {
      return this.resetPasswordForm.controls;
    }
    ngOnInit() {
    // console.log('this._route',this._route.url);
    // this._progressBarService.show();
      this._route.paramMap.pipe(
       switchMap((params: ParamMap) =>{
        // console.log({params});
        
        this.token=params.get('token');
        // console.log('this.token',this.token);
        
           return this._authService.validPasswordToken(
            this.token
          ).pipe(catchError(() => observableOf(null)));
         })
         ).subscribe({
           next:(result:any)=>{
            // this._progressBarService.hide();
            if(result)
              this.showForm=true;
            else
              this.showForm=false;
            
            
           },
           error:(err:any)=>{
              this.showForm=false;
            
            // this._progressBarService.hide();
             console.error(err);
           }
         });
     
   }
  error:string|null=null;
  
  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }
  toggleConfirmPasswordVisibility(): void {
    this.showConfirmPassword = !this.showConfirmPassword;
  }
  
  onFormSubmit() {    
    // this._progressBarService.show();
    this._authService.resetPassword(this.resetPasswordForm.get('password')?.value,this.token).subscribe({next:(result:any)=>{
        this._router.navigate(['/login']).then((navigated: boolean) => {
          if(navigated) {
            this._snackBarService.openSnackBar(result.message, '');
          }
        });    
        // this._progressBarService.hide();      
    },
    error:(err:any)=>{        
      console.log(err);      
      this.error=err.error.message||'Invalid Token';
    }});
  }
}
