<div class="bg-white rounded-2xl py-4 mt-6">
    <!-- <p class="font-semibold text-2xl text-[#2D3393] pl-4 pb-4">Upload Summary</p> -->
    <div class="flex items-center justify-between">
      <!-- <p class="font-semibold text-2xl text-[#2D3393] pl-4 ">Upload Summary</p> -->
      <p class="font-semibold text-2xl text-[#2D3393] pl-4 pb-4">Upload Summary</p>
      <!-- <div>
        <button routerLink="template/logs" class="bg-white border border-[#2D3393] rounded-3xl px-6 py-1 mr-2 text-[#2D3393]">View
          List</button>
      </div> -->
    </div>
    <div class="rounded-2xl overflow-hidden">
      <table class="table-auto w-full p-4 rounded-lg" *ngIf="data.length; else no_data">
        <thead class="bg-[#2D3393] text-white">
          <tr class="text-center ">
            <th class="p-2">Business Admin</th>
            <th class="p-2">Business Admin ID</th>
            <th class="p-2">Templates Submitted</th>
            <th class="p-2">Templates Approved</th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-center " *ngFor="let item of data; ; let i=index;" [ngClass]="{'bg-[#E7E9FF]' : (i%2)==0}">
            <td class="p-2">{{item.userName}}</td>
            <td class="p-2">{{item._id}}</td>
            <td class="p-2">{{item.totalcount}}</td>
            <td class="p-2">{{item.approved_count}}</td>
          </tr>          
        </tbody>
      </table>
      <ng-template #no_data><div class="m-2 p-4 rounded-lg bg-[#E7E9FF]">No data...</div></ng-template>
    </div>
</div>
<app-pagination   [totalRecords]="resultsLength" [recordsPerPage]="pageSize" (onPageChange)="displayActivePage($event)"></app-pagination>