import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

export const guestGuard: CanActivateFn = (route, state) => {
  const _authService = inject(AuthService);
  const router = inject(Router);  
  if(_authService.isLoggedOut()){
    return true;
  }
  return router.parseUrl('/');
  // return true;
};
