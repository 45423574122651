import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { api_endpoints, template_base_url } from 'src/app/api/api';

export interface templateApi {
  templates: templates[];
  inlineCount: number;
  totalPage:number;
}
export interface errorLogsApi {
  logs: templates[];
  inlineCount: number;
  totalPage:number;
}

export interface templates {
  created_at: string;
  number: string;
  state: string;
  title: string;
}

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  
  constructor(private _httpClient: HttpClient) {}  
  getSAHome(): Observable<templateApi> {    
    return this._httpClient.get<any>(api_endpoints.reporting.sa_home);
  }
  getBAHome(): Observable<templateApi> {    
    return this._httpClient.get<any>(api_endpoints.reporting.ba_home);
  }  
  getUserHome(): Observable<templateApi> {    
    return this._httpClient.get<any>(api_endpoints.reporting.user_home);
  }  
  getAdminTemplatesUploadSummary(sort: string, order: any, page: number,count:number): Observable<templateApi> {      
    const requestUrl = `${api_endpoints.template.upload_summary}?count=${count}&sort=${sort}&order=${order}&page=${page}`;
    return this._httpClient.get<templateApi>(requestUrl);
  }
  getSubmissionStatus(): Observable<templateApi> {    
    return this._httpClient.get<any>(api_endpoints.template.submission_status);
  }
  getTemplatesToBeFiled(): Observable<templateApi> {    
    return this._httpClient.get<any>(api_endpoints.template.to_be_filed);
  }  
  getTemplateErrorLogsById(userRole: string,id:any):Observable<any>{
    return this._httpClient.get(`${(userRole=='Business Admin')?api_endpoints.template.admin_error_log:api_endpoints.template.user_error_log}/${id}`);
  }
  getTemplateErrorLogs(userRole: string,sort: string, order: any, page: number,count:number): Observable<errorLogsApi> {      
    const requestUrl = `${(userRole=='Business Admin')?api_endpoints.template.admin_error_log:api_endpoints.template.user_error_log}?count=${count}&sort=${sort}&order=${order}&page=${page}`;
    return this._httpClient.get<errorLogsApi>(requestUrl);
  }
  getTemplates(sort: string, order: any, page: number,count:number,filter: any): Observable<templateApi> {  
    let requestUrl = `${template_base_url}?count=${count}&sort=${sort}&order=${order}&page=${page}`;
    return this._httpClient.post<templateApi>(requestUrl,{"filter":filter});
  }
  getTemplatesById(id:any):Observable<any>{
    return this._httpClient.get(`${api_endpoints.template.details}/${id}`);
  }
  addTempTemplates(file: File,id:string|null): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();    
    if(file)
    formData.append('templatefile', file);
    if(id){      
      formData.append('last_temp_file_id', id);
    }
    return this._httpClient.post(api_endpoints.upload.add_template, formData,{
      observe:'events',
      reportProgress: true,
    });
  }
  createTemplates(data:any,id:string): Observable<any> {  
    return this._httpClient.post(`${api_endpoints.template.create}/${id}`,data);  
  }
  getTemplateUserLogs(id:any,sort: string, order: any, page: number,count:number,filter:any):Observable<any>{
    return this._httpClient.post(`${api_endpoints.template.user_log}/${id}?count=${count}&sort=${sort}&order=${order}&page=${page }`,{"filter":filter});
  }
  action(id:string,action:boolean):Observable<any>{
    return this._httpClient.post(`${api_endpoints.template.action}/${id}`,{"action":action});    
  }
  shareWithUser(users:any[],id:any):Observable<any>{
    return this._httpClient.post(`${api_endpoints.template.share}/${id}`,{"users":users});
  }
  updateTemplates(data:any,id:any):Observable<any>{
    return this._httpClient.put(`${api_endpoints.template.create}/${id}`,data);
  }  
  addRemarks(data:any,file: File|undefined,isSaRemarks:boolean=false): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    let url=(isSaRemarks?api_endpoints.upload.add_sa_remarks:api_endpoints.upload.add_user_remarks); 
    if(file){
      formData.append('remarkfile', file); 
    }  
    formData.set('remark', data.remark||"");
    formData.set('template', data.template||"");
    if(data.forUser){
      formData.set('forUser', data.forUser||"");
    }
    formData.set('remarksBy', data.remarksBy||"");
    const httpHeaders:HttpHeaders=new HttpHeaders();
    return this._httpClient.post(url, formData,{
      headers:httpHeaders,
      observe:'events',
      reportProgress: true,
    });
  }
  uploadFillTemplate(id:any,file: File|undefined){
    const formData: FormData = new FormData();
    let url=`${api_endpoints.upload.add_fill_template}/${id}`;    
    if(file){
      formData.append('templatefile', file);
    }
    const httpHeaders:HttpHeaders=new HttpHeaders();    
    return this._httpClient.post(url, formData,{
      headers:httpHeaders,
      observe:'events',
      reportProgress: true,
    });
  }
  getUploadHistory(sort: string, order: any, page: number,count:number,filter:any): Observable<templateApi> {    
    const requestUrl = `${api_endpoints.template.fill}?count=${count}&sort=${sort}&order=${order}&page=${page}`;
    return this._httpClient.post<any>(requestUrl,{"filter":filter});
  }  
  getRemarks(template:any,forUser:any,onlyAdmin:boolean):Observable<any>{    
    return this._httpClient.get(`${api_endpoints.template.user_remarks}/${template}/${forUser}?onlyAdmin=${onlyAdmin}`);
  }
}
