import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarService } from './services/snack-bar.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'maruti-suzuki-collate-x';
  constructor(public _authService:AuthService,_snackBar:MatSnackBar,_snackBarService:SnackBarService){
    _snackBarService.snackBar$.subscribe({next:(data:any)=>{
      // console.log('snackBar$',{data});
      
      if(data.message){
        _snackBar.open(data.message, data.action||'', {
          duration: 3000,
          verticalPosition: 'bottom',
        });
      }
    },
    error:(err:any)=>{        
      console.log(err);
      
      // return throwError(()=>new Error('Product credentials are invalid.'));
      // this.error=err.error.message||'Login credentials are invalid';
    }});
  }
}
