

<div class="flex h-screen bg-gray-100">
    <div class="w-1/2 p-8 bg-white">
      <div class="flex items-center gap-2">
        <img src="assets/core-images/logo.png" alt="" />
        <img src="assets/core-images/Collate.svg" alt="" width="63px" />
        <img src="assets/core-images/X-logo.svg" alt="" width="28px" />
      </div>
      <div class="flex items-center justify-center">
        <div class="w-3/4 h-3/4 flex-col gap-4 mt-10">
            <div class="w-full mt-4 ">
                <p class="font-semibold text-xl">Reset Password</p> 
            </div>     
            <div *ngIf="!this.showForm" class="p-4 my-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" >
                This password reset token is invalid. <a routerLink="/forgot-password">Try Again</a>
            </div> 
               
            <form [formGroup]="resetPasswordForm" (submit)="onFormSubmit()" *ngIf="this.showForm">                      
                <div class="mt-4">
                    <div class="flex flex-col gap-2">
                        <label for="password" class="font-semibold">Password</label>
                        <div class="form-input border p-3 rounded-3xl flex justify-between items-center mt-2" >
                            <input autocomplete="false" id="password" appTrimText matInput [type]="showPassword?'text':'password'" class="bg-transparent outline-none w-full" minlength="6" pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,20}$" maxlength="20" placeholder="Ex. ********" formControlName="password"  required >
                    
                            <i (click)="togglePasswordVisibility()"
                            class="cursor-pointer fas fa-regular " [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'"
                            style="color: #2d3393"
                            ></i>
                        </div>
                        <div class="text-red-600"  *ngIf="f['password'].invalid && (f['password'].dirty || f['password'].touched)">

                            <span *ngIf="f['password'].errors?.['required']">Password is <strong>required</strong></span>
                            <span *ngIf="f['password'].errors?.['minlength']">Password must be at least 6 characters</span>
                            <span *ngIf="f['password'].errors?.['maxlength']">Password must not exceed 20 characters</span>
                            
                            <span *ngIf="!f['password'].errors?.['minlength']&&!f['password'].errors?.['maxlength']&&f['password'].errors?.['pattern']">At least 1 letter, 1 number and 1 special character </span>
                             
                        </div>
                    </div>
                    <div class="mt-4 flex flex-col gap-2">
                        <label for="confirm_password" class="font-semibold">Confirm Password</label>
                        <div class="form-input border p-3 rounded-3xl flex justify-between items-center mt-2">
                            <input autocomplete="false" id="confirm_password" appTrimText matInput [type]="showConfirmPassword?'text':'password'" class="bg-transparent outline-none w-full" minlength="6" pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,20}$" maxlength="20" placeholder="Ex. ********" formControlName="confirmpassword"  required >
                    
                            <i (click)="toggleConfirmPasswordVisibility()"
                            class="cursor-pointer fas fa-regular " [ngClass]="showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'"
                            style="color: #2d3393"
                            ></i>
                        </div>
                        <div class="text-red-600"  *ngIf="f['confirmpassword'].invalid && (f['confirmpassword'].dirty || f['confirmpassword'].touched)">

                            <span *ngIf="f['confirmpassword'].errors?.['required']">Confirm Password is <strong>required</strong></span>
                            <span *ngIf="f['confirmpassword'].errors?.['minlength']">Confirm Password must be at least 6 characters</span>
                            <span *ngIf="f['confirmpassword'].errors?.['maxlength']">Confirm Password must not exceed 20 characters</span>
                            <span *ngIf="f['confirmpassword'].errors?.['passwordNotMatch']">Confirm Password is not match with <strong>Password</strong></span>
                            
                            <span *ngIf="!f['confirmpassword'].errors?.['minlength']&&!f['confirmpassword'].errors?.['maxlength']&&f['confirmpassword'].errors?.['pattern']">At least 1 letter, 1 number and 1 special character </span>
                             
                         </div>
                    </div>
    
                    <div class="mt-4 flex items-center justify-between">                        
                        <button
                            type="submit"  type="submit"  [disabled]="!resetPasswordForm.valid"
                            class="cursor-pointer bg-msil-blue text-white px-5 py-3 rounded-3xl flex items-center justify-between w-1/2"
                            [ngClass]="{'opacity-50 cursor-not-allowed' : !resetPasswordForm.valid}" 
                        >
                            <span>SUBMIT</span>
                            <i
                            class="fas fa-sharp fa-regular fa-arrow-right"
                            style="color: #ffffff"
                            ></i>
                        </button>
                        <a routerLink="/login" class="text-msil-blue">Login</a>
                    </div>
                </div>
            </form>
        </div>
      </div>
    </div>
    <div
      class="w-1/2 bg-cover bg-center p-4"
      style="background-image: url(../../../assets/bg/login-page-img/pattern.png)"
    >
      <div class="flex flex-row-reverse">
        <img src="assets/core-images/powered_logo.png" class="" alt="" />
      </div>
      <img src="assets/bg/login-page-img/side-img1.svg" class="px-20 py-5" alt=""> 
    </div>
  </div>