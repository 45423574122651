  <div class="flex mt-4 bg-white p-5 gap-2 rounded-3xl py-5 justify-between">
    

    <div class="flex-1 flex items-center gap-4 border-r">
      <div class="bg-[#FFEBEB] w-24 h-24 rounded-full overflow-hidden flex items-center justify-center">
        <!-- <i class="fas fa-regular fa-circle-exclamation-check fa-2x" style="color: #ff2e00;"></i> -->
        <img src="assets/icons/home-icon1.svg" alt="Templates Pending" class="w-auto h-auto" />

      </div>
      <div>
        <p class="text-[#ACACAC]">Templates <br> Pending</p>
        <p class="font-semibold text-3xl">{{data.pending_templates||0}}</p>

      </div>
    </div>

    <div class="flex-1 flex items-center gap-4 border-r">
      <div class="bg-[#E1FFEF] w-24 h-24 rounded-full overflow-hidden flex items-center justify-center">
        <i class="fas fa-regular fa-circle-check fa-2xl" style="color: #00ac4f;"></i>
      </div>
      <div>
        <p class="text-[#ACACAC]">Templates <br> Submitted</p>
        <p class="font-semibold text-3xl">{{data.submitted_templates||0}}</p>

      </div>
    </div>

    <div class="flex-1 flex items-center gap-4 border-r">
      <div class="bg-[#FFEBEB] w-24 h-24 rounded-full overflow-hidden flex items-center justify-center">
        <i class="fas fa-regular fa-circle-xmark fa-2xl" style="color: #ff1c1c;"></i>
      </div>
      <div>
        <p class="text-[#ACACAC]">Templates <br> Rejected</p>
        <p class="font-semibold text-3xl">{{data.rejected_templates||0}}</p>

      </div>
    </div>

    <div class="flex-1 flex items-center gap-4 border-r">
      <div class="bg-[#FFF8EB] w-24 h-24 rounded-full overflow-hidden flex items-center justify-center">
        <i class="fas fa-regular fa-stopwatch fa-2xl" style="color: #FF891C;"></i>
      </div>
      <div>
        <p class="text-[#ACACAC]">Templates <br> Due</p>
        <p class="font-semibold text-3xl">{{data.due_templates||0}}</p>
      </div>
    </div>

    <div class="flex-1 flex items-center gap-4">
      <div class="bg-[#EAFFFC] w-24 h-24 rounded-full overflow-hidden flex items-center justify-center">
        <!-- <i class="fas fa-sharp fa-light fa-memo-circle-check fa-2xl" style="color: #219BA3;"></i> -->
        <img src="assets/icons/home-icon2.svg" alt="Templates Pending" class="w-auto h-auto" />

      </div>
      <div>
        <p class="text-[#ACACAC]">Templates <br> Closed</p>
        <p class="font-semibold text-3xl">{{data.close_templates||0}}</p>
      </div>
    </div>
  </div>

  <div class="mt-6">
    

    <app-submission-status></app-submission-status>
    <app-error-logs></app-error-logs>

    <!-- <div class="bg-white rounded-2xl py-4 mt-6">
      <p class="font-semibold text-2xl text-[#2D3393] pl-4 pb-4">Error Logs: Template Approval</p>
      <div class="rounded-2xl overflow-hidden">
        <table class="table-auto w-full p-4 rounded-lg">
          <thead class="bg-[#2D3393] text-white">
            <tr>
              <th class="p-2">Date</th>
              <th class="p-2">Template Name</th>
              <th class="p-2">Error Type</th>
              <th class="p-2">Details</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-center bg-[#E7E9FF]">
              <td class="p-2">03/01/2024</td>
              <td class="p-2">AB12</td>
              <td class="p-2">Upload Error</td>
              <td class="p-2"><button class="bg-[#2D3393] text-white rounded-3xl px-6 py-1" id="templateDetailsError"
                  onclick="siderbarItemsClick(`${id}`)">View</button></td>
            </tr>
            <tr class="text-center">
              <td class="p-2">03/01/2024</td>
              <td class="p-2">AB12</td>
              <td class="p-2">Upload Error</td>
              <td class="p-2"><button class="bg-[#2D3393] text-white rounded-3xl px-6 py-1" id="templateDetailsError"
                  onclick="siderbarItemsClick(`${id}`)">View</button></td>
            </tr>
            <tr class="text-center bg-[#E7E9FF]">
              <td class="p-2">03/01/2024</td>
              <td class="p-2">AB12</td>
              <td class="p-2">Upload Error</td>
              <td class="p-2"><button class="bg-[#2D3393] text-white rounded-3xl px-6 py-1" id="templateDetailsError"
                  onclick="siderbarItemsClick(`${id}`)">View</button></td>
            </tr>
            <tr class="text-center">
              <td class="p-2">03/01/2024</td>
              <td class="p-2">AB12</td>
              <td class="p-2">Upload Error</td>
              <td class="p-2"><button class="bg-[#2D3393] text-white rounded-3xl px-6 py-1" id="templateDetailsError"
                  onclick="siderbarItemsClick(`${id}`)">View</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div> -->
  </div>
  <!-- add pagination here -->
  <div class="mt-6">
    <!-- Centered Pagination -->
    <!-- <div class="flex justify-center items-center mt-4">
      <button class=" px-5 py-3 "><i class="fas fa-solid fa-angle-left"></i></button>
      <span class="mx-2">1</span>
      <span class="mx-2"><button class="bg-[#2D3393] px-3 py-1 rounded-md text-white">2</button></span>
      <span class="mx-2">3</span>
      <span class="mx-2">...</span>
      <span class="mx-2">12</span>
      <button class="bg-[#D5D6E9] px-3 py-1 rounded-md text-[#686868]"><i
          class="fas fa-solid fa-angle-right"></i></button>
    </div> -->
  </div>