import { Component } from '@angular/core';
import { TemplateService } from 'src/app/modules/template/services/template.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.css']
})
export class AdminHomeComponent {

  user:any;
  data:any={};
  constructor(public _authService: AuthService,
    private _templateService: TemplateService) {
      this.load();
  }
  load() {
    this._templateService!.getBAHome().subscribe({
      next: (result: any) => {
        this.data = result;
      },
      error: (err: any) => {
        this.data = {};
      }
    });
  }
}
