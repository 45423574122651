
    
    <div class="flex mt-4 bg-white px-5 items-center justify-center gap-8 rounded-3xl py-3 ">

      <div class="flex-1 flex items-center gap-4 border-r justify-around">
        <div class="bg-[#FFEBEB] w-[84px] h-[84px] rounded-full overflow-hidden flex items-center justify-center">
          <!-- <i class="fas fa-regular fa-circle-exclamation-check fa-2x" style="color: #ff2e00;"></i> -->
          <img src="assets/icons/home-icon1.svg" alt="Templates Pending" class="w-auto h-auto" />
        </div>
        <div>
          <p class="text-[#ACACAC]">Templates <br> Pending</p>
          <p class="font-semibold text-3xl">{{data.pending_templates||0}}</p>
        </div>
        <div  routerLink="template/pending"  class="w-8 h-8 text-[#2D3393] bg-[#E8E9FF] rounded-full flex items-center justify-center cursor-pointer">
          <i class="fas fa-regular fa-angle-right fa-lg"></i>
          
          

        </div>
      </div>

      <div class="flex-1 flex items-center gap-4 border-r justify-around">
        <div class="bg-[#E1FFEF] w-[84px] h-[84px] rounded-full overflow-hidden flex items-center justify-center">
          <i class="fas fa-regular fa-circle-check fa-2xl" style="color: #00ac4f;"></i>
        </div>
        <div>
          <p class="text-[#ACACAC]">Templates <br> Approved</p>
          <p class="font-semibold text-3xl">{{data.approved_templates||0}}</p>
        </div>
        <div routerLink="template/approved" class="w-8 h-8 text-[#2D3393] bg-[#E8E9FF] rounded-full flex items-center justify-center cursor-pointer">
          <i class="fas fa-regular fa-angle-right fa-lg"></i>
        </div>
      </div>

      <div class="flex-1 flex items-center gap-4 justify-around">
        <div class="bg-[#FFEBEB] w-[84px] h-[84px] rounded-full overflow-hidden flex items-center justify-center">
          <i class="fas fa-regular fa-circle-xmark fa-2xl" style="color: #ff1c1c;"></i>
        </div>
        <div>
          <p class="text-[#ACACAC]">Templates <br> Rejected</p>
          <p class="font-semibold text-3xl">{{data.rejected_templates||0}}</p>
        </div>
        <div routerLink="template/rejected" class="w-8 h-8 text-[#2D3393] bg-[#E8E9FF] rounded-full flex items-center justify-center cursor-pointer">
          <i class="fas fa-regular fa-angle-right fa-lg"></i>
        </div>
      </div>
    </div>

    <div class="mt-6">
      
      <app-submission-status></app-submission-status>
      <!-- <div class="bg-white rounded-2xl py-4 mt-4">
        <div class="flex items-center justify-between">
          <p class="font-semibold text-2xl text-[#2D3393] pl-4 ">Submission Status</p>
          <div>
            <button class="bg-white border border-[#2D3393] rounded-3xl px-6 py-1 mr-2 text-[#2D3393]">View
              List</button>
          </div>
        </div>
        <div class="rounded-2xl overflow-hidden mt-4">
          <table class="table-auto w-full p-4 rounded-lg">
            <thead class="bg-[#2D3393] text-white">
              <tr>
                <th class="p-2">Template Name</th>
                <th class="p-2">Template ID</th>
                <th class="p-2">Shared To</th>
                <th class="p-2">Filled By</th>
                <th class="p-2">Due Date</th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center bg-[#E7E9FF]">
                <td class="p-2">AB12</td>
                <td class="p-2">1234</td>
                <td class="p-2">50</td>
                <td class="p-2">50</td>
                <td class="p-2">03/01/2024</td>
              </tr>
              <tr class="text-center">
                <td class="p-2">AB12</td>
                <td class="p-2">1234</td>
                <td class="p-2">50</td>
                <td class="p-2">50</td>
                <td class="p-2">03/01/2024</td>
              </tr>
              <tr class="text-center bg-[#E7E9FF]">
                <td class="p-2">AB12</td>
                <td class="p-2">1234</td>
                <td class="p-2">50</td>
                <td class="p-2">50</td>
                <td class="p-2">03/01/2024</td>
              </tr>
              <tr class="text-center">
                <td class="p-2">AB12</td>
                <td class="p-2">1234</td>
                <td class="p-2">50</td>
                <td class="p-2">50</td>
                <td class="p-2">03/01/2024</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> -->

      <app-upload-summary></app-upload-summary>
      
    </div>
    <!-- add pagination here -->
    <!-- <div class="mt-6"> -->
      <!-- Centered Pagination -->
      <!-- <div class="flex justify-center items-center mt-4">
        <button class=" px-5 py-3 "><i class="fas fa-solid fa-angle-left"></i></button>
        <span class="mx-2">1</span>
        <span class="mx-2"><button class="bg-[#2D3393] px-3 py-1 rounded-md text-white">2</button></span>
        <span class="mx-2">3</span>
        <span class="mx-2">...</span>
        <span class="mx-2">12</span>
        <button class="bg-[#D5D6E9] px-3 py-1 rounded-md text-[#686868]"><i
            class="fas fa-solid fa-angle-right"></i></button>
      </div>
    </div> -->
