import { Component } from '@angular/core';
import { TemplateService } from 'src/app/modules/template/services/template.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-user-home',
  templateUrl: './user-home.component.html',
  styleUrls: ['./user-home.component.css']
})
export class UserHomeComponent {
  isLoding=false;
  data:any={};
  constructor(public _authService: AuthService,
    private _templateService: TemplateService) {
      this.load();
  }
  load() {
    this.isLoding=true;
    this._templateService!.getUserHome().subscribe({
      next: (result: any) => {
        this.isLoding=false;
        this.data = result;
      },
      error: (err: any) => {
        this.isLoding=false;
        this.data = {};
      }
    });
  }
}
