import { Component } from '@angular/core';
import * as moment from 'moment';
import { TemplateService } from 'src/app/modules/template/services/template.service';
import { AuthService } from 'src/app/services/auth.service';
import { merge, startWith, switchMap, catchError, map, of as observableOf } from 'rxjs';

@Component({
  selector: 'app-upload-summary',
  templateUrl: './upload-summary.component.html',
  styleUrls: ['./upload-summary.component.css']
})
export class UploadSummaryComponent {
  user:any;
  data:any=[];
  
  pageSize = 3;
  resultsLength = 0;
  activePage: number = 1;
  isRateLimitReached = false;
  constructor(public _authService: AuthService,
    private _templateService: TemplateService) {
      // this.getData();
      // this.load();
      // const _user=_authService.getLoginUser(); 
      // if(_user){
      //   this.user=_user;
      // }
    // this.activePage =1;
  }
  // getdiff(date:any){
  //   var date1 = moment(new Date(date));
  //   var date2 = moment.now();
  //   var days = date1.diff(date2, 'days') 
  //   return days;
  // }
  
  displayActivePage(activePageNumber: number) {
    this.activePage = activePageNumber;    
    this.getData();
  }
  
  getData() {
    merge(this.activePage)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this._templateService!.getAdminTemplatesUploadSummary(
            '_id',
            'desc',
            this.activePage,
            this.pageSize
          ).pipe(catchError(() => observableOf(null)));
        }),
        map(data => {
          // console.log({ data });

          // this.isLoadingResults = false;
          this.isRateLimitReached = data === null;

          if (data === null) {            
            return [];
          }
          // this.resultsLength = data.total_count;
          // return data.items;

          this.resultsLength = data.inlineCount;
          return data.templates;
        }),
      )
      .subscribe(data => (this.data = data));
  }
  // load() {
  //   this._templateService!.getAdminTemplatesUploadSummary().subscribe({
  //     next: (result: any) => {
  //       this.data = result.templates;
  //     },
  //     error: (err: any) => {
  //       this.data = [];
  //     }
  //   });
  // }
}
