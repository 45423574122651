import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  user!:any;
  constructor(public _authService:AuthService){
    const _user=_authService.getLoginUser(); 
    if(_user){
      this.user=_user;
    }
  }
}
