import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent {
  activeMenu="";
  constructor(public _authService:AuthService){
    this.activeMenu="";
  }
  toggleDropdown(menu:string){
    this.activeMenu=(menu==this.activeMenu)?"":menu; 
  }
}
